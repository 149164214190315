import { ServerError } from "./errors";

type ApiCallParams = {
  url: string;
  authorizationToken: string;
  method?: "GET" | "POST";
  requestBody?: unknown;
  mode?: RequestInit["mode"];
};
export const apiCall = async <T extends {}>({
  url,
  authorizationToken,
  method = "GET",
  requestBody,
  mode,
}: ApiCallParams): Promise<T> => {
  const requestInit: RequestInit = {
    method,
    headers: {
      Authorization: "Bearer " + authorizationToken,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(requestBody),
    mode,
  };

  const response = await fetch(url, requestInit);

  if (response.status !== 200) throw await ServerError.fromResponse(response);

  return response.json();
};
