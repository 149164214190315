import { apiCall } from "./apiCall";

const PATH = {
  facetecStartSession: "/facetec/start-session",
};

export type FacetecSdkParams = {
  publicFaceMapEncryptionKey: string;
  deviceKeyIdentifier: string;
  productionKey: string;
};

export type FaceTecStartSession = {
  sessionToken: string;
  facetecSdkParams: FacetecSdkParams;
};

export const facetecStartSession = (url: string, loginChallenge: string) =>
  apiCall<FaceTecStartSession>({
    url: url + PATH.facetecStartSession,
    authorizationToken: loginChallenge,
    method: "POST",
  });
