import { apiCall } from "./apiCall";

const PATH = {
  getPaymentInfo: "/payment/info",
  pollPayment: "/payment/poll",
};

export type PaymentInfo = {
  contractAddress: string;
  ticket: string;
  chainId: string;
};

export type PaymentPoll = { payed: boolean };

export const getPaymentInfo = (url: string, loginChallenge: string) =>
  apiCall<PaymentInfo>({
    url: url + PATH.getPaymentInfo,
    authorizationToken: loginChallenge,
  });

export const pollPayment = (url: string, loginChallenge: string) =>
  apiCall<PaymentPoll>({
    url: url + PATH.pollPayment,
    authorizationToken: loginChallenge,
  });
