import FaceTecSDK from "facetec-sdk";
import theme from "../../../theme";
import customizationFromTheme from "./customizationFromTheme";

export type FaceTecInitParams = {
  publicFaceMapEncryptionKey: string;
  deviceKeyIdentifier: string;
  productionKey: string;
};

const prodMode = (
  publicFaceMapEncryptionKey: string,
  deviceKeyIdentifier: string,
  productionKey: string
): Promise<boolean> =>
  new Promise((resolve) => {
    const rand = Math.random();
    console.log(
      `[${rand}] Calling FaceTecSDK.initializeInProductionMode with `,
      productionKey,
      deviceKeyIdentifier,
      publicFaceMapEncryptionKey
    );
    const traceResolve = (val: boolean) => {
      console.log(
        `[${rand}] Resolved FaceTecSDK.initializeInProductionMode with ${val}`
      );
      resolve(val);
    };

    // Ok, there's yet another problem with FaceTec.
    // FaceTecSDK.initializeInProductionMode TypeScript signature has the first
    // argument (`productionKey`) typed as `string`, but the underlying
    // JavaScript errors out if we actually pass the `string` there.
    // However, the production key itself looks like a valid JSON, and if we
    // just parse it and use it as an object - the underlying JavaScript
    // happily accepts the key.
    // Why? Who knows...
    // Here we provide a workaround that parses the string into and object, and
    // then makes TypeScript think it is still a `string` - so we can pass
    // as object (under the hood) where string is expected at the type level.
    // Thanks FaceTec.
    const correctedProductionKey: string =
      typeof productionKey === "string"
        ? (JSON.parse(productionKey) as any as string)
        : productionKey;

    FaceTecSDK.initializeInProductionMode(
      correctedProductionKey,
      deviceKeyIdentifier,
      publicFaceMapEncryptionKey,
      traceResolve
    );
  });

const faceTecInit = async (params: FaceTecInitParams): Promise<boolean> => {
  const { publicFaceMapEncryptionKey, deviceKeyIdentifier, productionKey } =
    params;

  FaceTecSDK.setResourceDirectory("/facetec/resources");

  const themedImagesDir = theme.palette.mode === "dark" ? "dark" : "light";
  FaceTecSDK.setImagesDirectory(`/facetec/images/${themedImagesDir}`);

  const customization = customizationFromTheme(theme);
  FaceTecSDK.setCustomization(customization);

  return prodMode(
    publicFaceMapEncryptionKey,
    deviceKeyIdentifier,
    productionKey
  );
};

export default faceTecInit;
