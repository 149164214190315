import { apiCall } from "./apiCall";

const PATH = {
  getConsentInfo: "/consent",
  acceptConsent: "/consent/accept",
  rejectConsent: "/consent/reject",
};

type ClientInfo = {
  clientId: string;
  clientName: string;
  owner: string;
  policyUri: string;
  tosUri: string;
  clientUri: string;
  logoUri: string;
  contacts: string[];
};

export type ConsentInfo =
  | { tag: "skip"; redirectTo: string }
  | { tag: "full"; client: ClientInfo; scopes: string[] };

export const getConsentInfo = (url: string, consentChallenge: string) =>
  apiCall<ConsentInfo>({
    url: url + PATH.getConsentInfo,
    authorizationToken: consentChallenge,
  });

export type AcceptConsent = {
  redirectTo: string;
};

export const acceptConsent = (url: string, consentChallenge: string) =>
  apiCall<AcceptConsent>({
    url: url + PATH.acceptConsent,
    authorizationToken: consentChallenge,
    method: "POST",
  });

export type RejectConsent = {
  redirectTo: string;
};

export const rejectConsent = (url: string, loginChallenge: string) =>
  apiCall<RejectConsent>({
    url: url + PATH.rejectConsent,
    authorizationToken: loginChallenge,
    method: "POST",
  });
