import { apiCall } from "./apiCall";

const PATH = {
  getLoginInfo: "/login",
  acceptLogin: "/login/accept",
  rejectLogin: "/login/reject",
};

export type FacetecSdkParams = {
  publicFaceMapEncryptionKey: string;
  deviceKeyIdentifier: string;
  productionKey: string;
};

type ClientInfo = {
  clientId: string;
  clientName: string;
  owner: string;
  policyUri: string;
  tosUri: string;
  clientUri: string;
  logoUri: string;
  contacts: string[];
};

export type LoginInfo =
  | { tag: "skip"; redirectTo: string }
  | {
      tag: "full";
      client: ClientInfo;
      paymentRequired: boolean;
    };

export const getLoginInfo = (url: string, loginChallenge: string) =>
  apiCall<LoginInfo>({
    url: url + PATH.getLoginInfo,
    authorizationToken: loginChallenge,
  });

export type AcceptLogin = {
  redirectTo: string;
};

export const acceptLogin = (
  url: string,
  loginChallenge: string,
  livenessData: unknown
) =>
  apiCall<AcceptLogin>({
    url: url + PATH.acceptLogin,
    authorizationToken: loginChallenge,
    method: "POST",
    requestBody: livenessData,
  });

export type RejectLogin = {
  redirectTo: string;
};

export const rejectLogin = (url: string, loginChallenge: string) =>
  apiCall<RejectLogin>({
    url: url + PATH.rejectLogin,
    authorizationToken: loginChallenge,
    method: "POST",
  });
