import { StateVariant } from "@tagged-state/core";
import { createContext, createTaggedStateHook } from "@tagged-state/react";

export type FacetecSdkParams = {
  publicFaceMapEncryptionKey: string;
  deviceKeyIdentifier: string;
  productionKey: string;
};

export type State =
  | StateVariant<"uninit">
  | StateVariant<"pending">
  | StateVariant<
      "ready",
      {
        sessionToken: string;
        facetecSdkParams: FacetecSdkParams;
      }
    >
  | StateVariant<"error", { error: Error }>;

const { Context, ComponentGuard } = createContext<State>({
  tag: "uninit",
  data: {},
});

export const FaceTecStartSessionContext = Context;
export const FaceTecStartSessionGuard = ComponentGuard;

export const useFaceTecStartSessionInfo = createTaggedStateHook(
  Context,
  "ready" as const
);
