import { Signer } from "ethers";
import React, { PropsWithChildren, useEffect, useState } from "react";
import {
  ISendPayment,
  ISendPayment__factory,
} from "../contract-payment-ethers";

import { State, ContractPaymentContext } from "./state";

type HexString = `0x${string}`;

type PaymentConnectProps = {
  singer: Signer;
  contractAddress: HexString;
};

const ContractPaymentInit: React.FC<PropsWithChildren<PaymentConnectProps>> = (
  props
) => {
  const { singer, contractAddress, children } = props;
  const [value, setValue] = useState<State>({ tag: "pending", data: {} });
  const [contract, setContract] = useState<ISendPayment>();

  useEffect(() => {
    const contract = ISendPayment__factory.connect(contractAddress, singer);
    setContract(contract);
  }, [contractAddress, singer]);

  useEffect(() => {
    if (!contract) return;

    contract
      .getPrice()
      .then((price) => {
        setValue({
          tag: "ready",
          data: {
            price,
            contract,
          },
        });
      })
      .catch((error: Error) => {
        setValue({ tag: "error", data: { error } });
      });
  }, [contract]);

  return <ContractPaymentContext.Provider value={value} children={children} />;
};

export default ContractPaymentInit;
