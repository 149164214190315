type ErrorData<Kind extends string, Payload extends {} | null> = {
  kind: Kind;
  payload: Payload;
};

type ErrorBody<T> = {
  data: T;
};

export type AnyErrorBody = ErrorBody<unknown>;

export const isErrorBody = (body: unknown): body is AnyErrorBody =>
  typeof body === "object" && body !== null && "data" in (body as any);

export type AnyErrorData = ErrorData<any, any>;

export const isErrorData = (data: unknown): data is AnyErrorData =>
  typeof data === "object" &&
  data !== null &&
  typeof (data as any).kind === "string" &&
  typeof (data as any).payload === "object";

export type ShouldRetryFaceScanErrorData = ErrorData<
  "ShouldRetryFaceScan",
  any
>;

export const isShouldRetryFaceScanErrorData = (
  data: null | AnyErrorData
): data is ShouldRetryFaceScanErrorData => data?.kind === "ShouldRetryFaceScan";

export type ShouldRestartFlowErrorData = ErrorData<"ShouldRestartFlow", any>;

export class ServerError extends Error {
  static async fromResponse(response: Response) {
    let body = null;
    try {
      body = (await response.json()) as unknown;
      console.log(`ServerError body: ${JSON.stringify(body)}`);
    } catch (error) {
      console.log(`ServerError body was not in JSON format`);
    }

    let errorData: null | AnyErrorData = null;

    if (isErrorBody(body) && isErrorData(body.data)) {
      errorData = body.data;
    }

    return new ServerError(response.status, errorData);
  }

  constructor(
    public readonly status: number,
    public readonly data: null | AnyErrorData
  ) {
    const message = `Server returned invalid status: ${status}`;
    super(message);
    this.name = "ServerError";
  }
}
