import { InitOptions } from "@web3-onboard/core";

const chains: Record<string, InitOptions["chains"][number]> = {
  "0x5": {
    id: "0x5",
    label: "Goerli",
    rpcUrl: "https://goerli.infura.io/v3/",
    token: "GoerliETH",
  },
  "0x1": {
    id: "0x1",
    label: "Ethereum",
    rpcUrl: "https://mainnet.infura.io/v3/",
    token: "ETH",
  },
};

const chainsMapper = (
  chainId: string,
  infuraId: string
): InitOptions["chains"][number] => {
  const chain = chains[chainId];

  if (!chain) {
    throw new Error("Unexpected chainId");
  }

  return {
    ...chain,
    rpcUrl: chain.rpcUrl + infuraId,
  };
};

export default chainsMapper;
