import { apiCall } from "./apiCall";

const PATH = {
  pollOnline: "/poll",
};

export type OnlinePoll = { pollInterval: number; online: number };

export const pollOnline = (url: string, loginChallenge: string) =>
  apiCall<OnlinePoll>({
    url: url + PATH.pollOnline,
    authorizationToken: loginChallenge,
  });
