import { Box, Divider, Popover, Stack, Typography } from "@mui/material";
import HexStringView from "./HexStringView";
import { Web3Provider } from "@ethersproject/providers";
import { ContractReceipt } from "ethers";
import { useState } from "react";

const blockNumberToPosition = (minBlock: number, maxBlock: number) => {
  const denominator = maxBlock - minBlock;
  return (currentBlock: number): number => {
    const value = 1 - (maxBlock - currentBlock) / denominator;
    return Math.round(value * 100);
  };
};

function boundaryValues(values: number[]): [number, number] | null {
  let mem: [number, number] | null = null;

  for (const value of values) {
    if (mem === null) {
      mem = [value, value];
      continue;
    }

    if (mem[0] > value) {
      mem[0] = value;
    }
    if (mem[1] < value) {
      mem[1] = value;
    }
  }

  return mem;
}

export const getTimelineBlocks = async (
  provider: Web3Provider,
  contractReceipt: ContractReceipt
) => {
  const promises = await Promise.allSettled([
    provider.getBlock("finalized"),
    provider.getBlock("latest"),
  ]);

  const [finalized, latest] = promises.map((result) => {
    if (result.status === "rejected") {
      throw new Error("get block was rejected");
    }
    return result.value;
  });

  const [min, max] = boundaryValues([
    finalized.number,
    latest.number,
    contractReceipt.blockNumber,
  ])!;
  const getCurrentBlock = blockNumberToPosition(min, max);

  const finalizedPosition = getCurrentBlock(finalized.number);
  const latestPosition = getCurrentBlock(latest.number);
  const txPosition = getCurrentBlock(contractReceipt.blockNumber);

  const timelineBlocks: TimelineBlock[] = [
    {
      id: "finalized",
      context: {
        title: "Finalized block",
        label: "finalized",
        blockNumber: finalized.number,
        hash: finalized.hash,
      },
      anchor: "bottom",
      position: finalizedPosition,
      color: "#66FFAD",
      colorCircl: "#66FFAD",
      className: "progress-bar",
      zIndex: (100 - finalizedPosition) / 10,
    },
    {
      id: "latest",
      context: {
        title: "Latest block",
        label: "latest",
        blockNumber: latest.number,
        hash: latest.hash,
      },
      anchor: "bottom",
      position: latestPosition,
      color: "#FCC765",
      colorCircl: "#FCC765",
      className: "progress-bar progress-bar-striped progress-bar-animated",
      zIndex: (100 - latestPosition) / 10,
    },
    {
      id: "transaction",
      context: {
        title: "Block with payment transaction",
        label: "tx",
        blockNumber: Number(contractReceipt.blockNumber),
        hash: contractReceipt.blockHash,
      },
      anchor: "top",
      position: txPosition,
      color: "rgba(0,0,0,0)",
      colorCircl: "rgba(0,0,0,0.5)",
      className: "progress-bar",
      zIndex: (100 - txPosition) / 10,
    },
  ];
  return timelineBlocks;
};

export type TimelineBlock = {
  id: string;
  context: {
    blockNumber: number;
    title: string;
    label: string;
    hash: string;
  };
  anchor: "top" | "bottom";
  position: number;
  className: string;
  color?: string;
  colorCircl?: string;
  zIndex?: number;
};
type TimelineViewProps = {
  style?: React.CSSProperties;
  blocks: Array<TimelineBlock>;
};
const TimelineWithBlocks: React.FC<TimelineViewProps> = (props) => {
  const { style = {}, blocks } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [openId, setOpenId] = useState<string | null>(null);

  const handlePopoverOpen =
    (id: string) => (event: React.MouseEvent<HTMLElement>) => {
      setOpenId(id);
      setAnchorEl(event.currentTarget);
    };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setOpenId(null);
  };

  return (
    <Box
      sx={{
        position: "relative",
        border: "2px solid white",
        height: 24,
        borderRadius: 5,
        width: "100%",
        minWidth: "350px",
        ...style,
      }}
    >
      {blocks.map((block) => {
        return (
          <Box
            key={`${block.id}_1`}
            style={{
              position: "absolute",
              width: "100%",
            }}
            zIndex={block.zIndex}
          >
            <Stack flexDirection="row">
              <Box
                sx={{
                  transition: "2s",
                  borderRadius: 10,
                  backgroundColor: block.color,
                  width: `calc(${block.position}% + 60px)`,
                }}
                className={block.className}
              />
              <Box
                key={`${block.id}_2`}
                sx={{
                  position: "relative",
                  borderRadius: 10,
                  backgroundColor: block.colorCircl,
                  height: 20,
                  width: 20,
                  transform: "translateX(-20px)",
                }}
                onMouseEnter={handlePopoverOpen(block.id)}
                onMouseLeave={handlePopoverClose}
              >
                <Popover
                  id={block.id}
                  key={`${block.id}_3`}
                  open={openId === block.id}
                  sx={{
                    pointerEvents: "none",
                  }}
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  onClose={handlePopoverClose}
                  disableRestoreFocus
                >
                  <Stack padding={2}>
                    <Typography align="center">
                      {block.context.title}
                    </Typography>
                    <Divider sx={{ marginY: 1 }} />
                    <Stack gap={1}>
                      <Typography>
                        Block number: {block.context.blockNumber}
                      </Typography>
                      <Typography>
                        Hash:{" "}
                        <HexStringView
                          color="white"
                          value={block.context.hash}
                        />
                      </Typography>
                    </Stack>
                  </Stack>
                </Popover>
                <Box
                  sx={{
                    position: "relative",
                    paddingTop: 3,
                    transform: "translateX(-50%)",
                  }}
                >
                  <Typography
                    fontSize={14}
                    sx={{
                      position: "absolute",
                      background: "#382e2e",
                      borderRadius: "10px",
                      paddingX: "10px",
                      border: "2px solid white",
                      transform:
                        block.anchor === "bottom" ? "" : "translateY(-52px)",
                    }}
                  >
                    {block.context.label}
                  </Typography>
                </Box>
              </Box>
            </Stack>
          </Box>
        );
      })}
    </Box>
  );
};

export default TimelineWithBlocks;
