import React, { useEffect, useMemo, useState } from "react";
import makeProcessor, { Effect } from "./makeProcessor";
import useStartCapture from "./useStartCapture";

type FaceTecCaptureProps = {
  onDone: () => void;
  onError: (error: Error) => void;
  handleLivenessData: (livenessData: unknown) => Promise<Effect | void>;
  sessionToken: string;
};

const FaceTecCapture: React.VFC<FaceTecCaptureProps> = (props) => {
  const { handleLivenessData, sessionToken, onDone, onError } = props;

  const processor = useMemo(
    () => makeProcessor({ onDone, handleLivenessData, onError }),
    [handleLivenessData, onDone, onError]
  );

  const [startCapture, facetecCaptureInProgress] = useStartCapture(
    processor,
    sessionToken
  );

  const [autostartTriggered, setAutostartTriggered] = useState(false);
  useEffect(() => {
    if (!autostartTriggered && !facetecCaptureInProgress) {
      setAutostartTriggered(true);
      startCapture();
    }
  }, [autostartTriggered, facetecCaptureInProgress, startCapture]);

  return null;
};

export default FaceTecCapture;
