import {
  Button,
  Snackbar,
  SxProps,
  Typography,
  TypographyTypeMap,
} from "@mui/material";
import { useMemo, useState } from "react";

type HexStringViewProps = TypographyTypeMap["props"] & {
  value: string;
  className?: string;
  ellipsisRightOffsetChars?: number;
  ellipsisLeftOffsetChars?: number;
  buttonSx?: SxProps;
};
const HexStringView: React.FC<HexStringViewProps> = (props) => {
  const {
    value,
    ellipsisLeftOffsetChars = 4,
    ellipsisRightOffsetChars = 4,
    className,
    buttonSx,
    ...typographyProps
  } = props;
  const [open, setOpen] = useState(false);

  const formattedHexString = useMemo(() => {
    return `${value.slice(0, ellipsisLeftOffsetChars + 2)}...${value.slice(
      value.length - ellipsisRightOffsetChars,
      value.length
    )}`;
  }, [ellipsisLeftOffsetChars, ellipsisRightOffsetChars, value]);

  const handleClick = () => {
    setOpen(true);
    navigator.clipboard.writeText(value);
  };

  const sxMemo = useMemo<SxProps>(
    () => ({
      textTransform: "inherit",
      ...buttonSx,
    }),
    [buttonSx]
  );

  return (
    <>
      <Button variant="text" sx={sxMemo} onClick={handleClick}>
        <Typography component="span" className={className} {...typographyProps}>
          {formattedHexString}
        </Typography>
      </Button>
      <Snackbar
        open={open}
        onClose={() => setOpen(false)}
        autoHideDuration={2000}
        message="Copied to clipboard"
      />
    </>
  );
};

export default HexStringView;
