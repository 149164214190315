import { InitOptions } from "@web3-onboard/core";
import { loadProduction } from "hybridconfig";

const baseUrlFormat = (url: string) => {
  return new URL(url).origin;
};

const modes = ["development", "production"];
const modeGuard = (value: string): value is MODE => {
  return modes.includes(value);
};

type AllowedKeys =
  | "baseUrl"
  | "onlinePollingUrl"
  | "pollInterval"
  | "mode"
  | "appMetadata"
  | "infuraId";

type MODE = "development" | "production";

const config = loadProduction<AllowedKeys>({
  remoteConfigPath: "/config.json",
  envKeyPrefix: "REACT_APP_",
}).then((config) => {
  const {
    baseUrl,
    onlinePollingUrl,
    pollInterval,
    mode,
    appMetadata,
    ...rest
  } = config;
  let appMetadataJSON;

  try {
    appMetadataJSON = JSON.parse(appMetadata) as InitOptions["appMetadata"];
  } catch (error) {
    throw new Error(`AppMetadata must have json format: ${error}`);
  }

  if (!modeGuard(mode)) {
    throw new Error(
      `Unknown mode: ${mode}, exprected one of [${modes.join(", ")}]`
    );
  }

  return {
    baseUrl: baseUrlFormat(baseUrl),
    onlinePollingUrl: onlinePollingUrl
      ? baseUrlFormat(onlinePollingUrl)
      : undefined,
    pollInterval: parseInt(pollInterval),
    mode,
    appMetadata: appMetadataJSON,
    ...rest,
  };
});

export default config;
