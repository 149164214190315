import React, { PropsWithChildren, useEffect, useState } from "react";
import { State, Web3OnboardContext } from "./web3OnboardInitState";
import { init } from "@web3-onboard/react";
import injectedModule from "@web3-onboard/injected-wallets";
import { InitOptions } from "@web3-onboard/core";
import walletConnectModule from "@web3-onboard/walletconnect";
import chainsMapper from "./chainsMapper";

const injected = injectedModule();

const walletConnect = walletConnectModule();

type Web3OnboardInitProps = {
  chainId: string;
  infuraId: string;
  appMetadata: InitOptions["appMetadata"];
};
const Web3OnboardInit: React.FC<PropsWithChildren<Web3OnboardInitProps>> = (
  props
) => {
  const { appMetadata, chainId, infuraId, children } = props;
  const [initialized, setInitialized] = useState(false);

  const [value, setValue] = useState<State>({
    tag: "pending",
    data: {},
  });

  useEffect(() => {
    if (initialized) return;
    const chain = chainsMapper(chainId, infuraId);

    init({
      wallets: [injected, walletConnect],
      chains: [chain],
      appMetadata,
      theme: "system",
    });
    setInitialized(true);
  }, [appMetadata, chainId, infuraId, initialized]);

  useEffect(() => {
    if (!initialized) return;

    setValue({
      tag: "ready",
      data: {},
    });
  }, [initialized]);

  return <Web3OnboardContext.Provider value={value} children={children} />;
};

export default Web3OnboardInit;
